<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="工单类型："
          v-model="searchParam.workTypeName"
          placeholder="请输入工单类型名称"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="edit(scope.row)"
        />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="dialogTitle"
      v-model="dialogFormVisible"
      width="50%"
      cancelTxt="返回"
      sureTxt="保存"
      @confirm="submit"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="工单类型：">
          <v-input
            placeholder="请输入工单类型名称"
            clearable
            class="filter-item"
            v-model="temp.name"
          ></v-input>
        </el-form-item>
        <el-form-item label="事件类型：">
          <div class="eventType">
            <div
              class="item"
              v-for="(item, index) in temp.incidentName"
              :key="index"
            >
              <div class="item-l">
                <div v-if="item.id">
                  {{ item.incidentName }}
                </div>
                <v-input
                  v-if="!item.id"
                  placeholder="请输入事件类型名称"
                  clearable
                  v-model="item.incidentName"
                ></v-input>
              </div>
              <div class="item-r">
                <v-button
                  text="删除"
                  type="danger"
                  @click="removeEventType(item, index)"
                ></v-button>
              </div>
            </div>
          </div>
          <div class="eventType">
            <div class="item">
              <div class="item-l"></div>
              <div class="item-r">
                <v-button @click="addEvent()" text="添加"></v-button>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="工单说明：">
          <v-input
            placeholder="请输入工单说明"
            clearable
            class="filter-item"
            v-model="temp.description"
          ></v-input>
        </el-form-item>
        <el-form-item label="是否置顶：">
          <v-radio :radioObj="isTopObj" v-model="temp.onTop"></v-radio>
        </el-form-item>
        <el-form-item label="置顶排序：">
          <v-input
            placeholder="请输入置顶排序"
            clearable
            class="filter-item"
            v-model="temp.topRanking"
          ></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  workOrderTypeListUrl,
  workOrderTypeDetailsUrl,
  removeWorkOrderTypeUrl,
  addWorkOrderTypeUrl,
  workOrderTypeCompileUrl,
  delIncidentTypeUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "workOrderType",
  data() {
    return {
      dialogTitle: "",
      dialogFormVisible: false,

      addEventDialogVisible: false,
      addEventTypeTitle: "添加事件类型",
      eventTypeName: { incidentName: "" },

      tableUrl: workOrderTypeListUrl,
      searchParam: {
        workTypeName: null,
      },
      workTypeList: [],
      headers: [
        {
          prop: "topRanking",
          label: "排序",
        },
        {
          prop: "name",
          label: "工单类型",
        },
        {
          prop: "list",
          label: "事件类型",
          formatter: (row, prop) => {
            let list = [];
            row.list.forEach((item, index) => {
              list.push(item.incidentName);
            });
            return list.join(",") || "-";
          },
        },
        {
          prop: "description",
          label: "工单说明",
        },
      ],
      isTopObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      temp: {
        id: null,
        name: null,
        description: null,
        onTop: null,
        topRanking: null,
        incidentName: [],
      },
      list: [],
      add_status: false,
    };
  },
  mounted() {},
  methods: {
    addEvent() {
      // if (this.add_status == false) {
      //   this.add_status = true;
      // } else {
      // if (
      //   !this.eventTypeName.incidentName ||
      //   this.eventTypeName.incidentName == ""
      // ) {
      //   this.$message.error("请输入事件类型名称！");
      //   return;
      // }
      let obj = {
        incidentName: "",
      };
      this.temp.incidentName.push(obj);
      console.log(this.temp.incidentName);
      // this.add_status = false;
      // }
    },
    submit() {
      //保存按钮
      if (this.dialogTitle == "新增") {
        if (!this.temp.name || this.temp.name == "") {
          this.$message.error("请输入工单类型名称！");
          return;
        }
        if (this.temp.incidentName.length == 0) {
          this.$message.error("请添加至少一个事件类型！");
          return;
        }
        let list = [];
        this.temp.incidentName.forEach((item) => {
          list.push(item.incidentName);
        });
        let params = {
          name: this.temp.name,
          description: this.temp.description,
          onTop: this.temp.onTop,
          topRanking: this.temp.topRanking,
          incidentName: list,
        };
        this.$axios.post(`${addWorkOrderTypeUrl}`, params).then((res) => {
          if (res.code === 200) {
            Message.success(res.msg);
            this.dialogFormVisible = false;
            this.$refs.list.search();
          }
        });
      } else if (this.dialogTitle == "编辑") {
        if (!this.temp.name || this.temp.name == "") {
          this.$message.error("请输入工单类型名称！");
          return;
        }
        if (this.temp.incidentName.length == 0) {
          this.$message.error("请添加至少一个事件类型！");
          return;
        }
        let params = {
          workOrderTypeId: this.temp.id,
          name: this.temp.name,
          description: this.temp.description,
          onTop: this.temp.onTop,
          topRanking: this.temp.topRanking,
          incidenList: this.temp.incidentName,
        };
        this.$axios.post(`${workOrderTypeCompileUrl}`, params).then((res) => {
          if (res.code === 200) {
            Message.success(res.msg);
            this.dialogFormVisible = false;
            this.$refs.list.search();
          }
        });
      }
    },
    add() {
      //新增按钮
      this.add_status = false;
      this.dialogTitle = "新增";
      this.temp.id = null;
      this.temp.name = null;
      this.temp.description = null;
      this.temp.onTop = null;
      this.temp.topRanking = null;
      this.temp.incidentName = [];
      this.dialogFormVisible = true;
    },
    edit(item) {
      //编辑按钮
      this.add_status = false;
      this.dialogTitle = "编辑";
      this.temp.id = item.id;
      this.getDetail(this.temp.id);
    },
    removeEventType(item, index) {
      //删除事件类型
      MessageBox.confirm(
        "是否删除该事件类型？注意：您删除该类型后，如果有用户关联此类型将会删除关联信息；如果用户关联的此类型是唯一类型，将同时删除工单处理人数据。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.temp.incidentName.splice(index, 1);
        if (item.id) {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${delIncidentTypeUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code == 200) {
              }
            });
        }
      });
    },
    getDetail(id) {
      //获取详情
      this.temp.incidentName = [];
      let params = {
        id: id,
      };
      this.$axios
        .get(`${workOrderTypeDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.temp.name = res.data.name;
            this.temp.description = res.data.description;
            this.temp.onTop = res.data.onTop;
            this.temp.topRanking = res.data.topRanking;
            this.temp.incidentName = res.data.list;
            // res.data.list.forEach((item, index) => {
            //   this.temp.incidentName.push(item.incidentName);
            // });
            console.log(this.temp.incidentName);
            this.dialogFormVisible = true;
          }
        });
    },
    remove(item) {
      //删除按钮
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${removeWorkOrderTypeUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              Message.success("操作成功！");
              this.$refs.list.search();
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;

  .box {
    display: flex;
    align-items: center;

    .left_box {
      width: 200px;
    }

    .right_box {
      margin-left: 20px;
      position: relative;

      .add_box {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .eventType {
    .item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .item-l {
        width: 200px;
        font-size: 14px;
        line-height: 16px;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      .item-r {
        margin-left: 20px;

        .v-control {
          margin-right: 10px;
        }
      }
    }
  }
  .filter-item {
    ::v-deep .el-input {
      width: 100% !important;
    }
  }
}
</style>
